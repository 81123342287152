import React, { SVGProps } from "react";

const ImageSendIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width || 24}
      height={props?.height || 24}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="gray"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14.265 15.938l-1.718-1.703c-.789-.783-1.184-1.175-1.638-1.321a2 2 0 00-1.23 0c-.454.146-.849.538-1.638 1.32l-4 4.028m10.224-2.324l.341-.339c.806-.8 1.209-1.199 1.671-1.345a2 2 0 011.247.015c.46.156.853.565 1.64 1.383L20 16.5m-5.735-.562l3.955 4.025m0 0c-.35.037-.799.037-1.42.037H7.2c-1.12 0-1.68 0-2.108-.218a2 2 0 01-.874-.874 1.869 1.869 0 01-.176-.646m14.178 1.7c.28-.03.498-.083.688-.18a2 2 0 00.874-.874C20 18.48 20 17.92 20 16.8V13m-9-9H7.2c-1.12 0-1.68 0-2.108.218a2 2 0 00-.874.874C4 5.52 4 6.08 4 7.2v9.6c0 .647 0 1.107.042 1.462M18 9V6m0 0V3m0 3h3m-3 0h-3"
      ></path>
    </svg>
  );
}

export default ImageSendIcon;