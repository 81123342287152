import React, { useEffect } from 'react';

interface ITicketProps {
  product: any
}
const Ticket = ({ product }: ITicketProps) => {
  useEffect(() => {
    if (product) {
      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
      script.async = true;
      script.innerHTML = JSON.stringify({
        "symbols": [
          {
            "proName": "FOREXCOM:SPXUSD",
            "title": "S&P 500 Index"
          },
          {
            "proName": "FOREXCOM:NSXUSD",
            "title": "US 100 Cash CFD"
          },
          {
            "proName": "FX_IDC:EURUSD",
            "title": "EUR to USD"
          },
          {
            "proName": "BITSTAMP:BTCUSD",
            "title": "Bitcoin"
          },
          {
            "proName": "BITSTAMP:ETHUSD",
            "title": "Ethereum"
          }
        ],
        "showSymbolLogo": false,
        "isTransparent": true,
        "displayMode": "regular",
        "colorTheme": "dark",
        "locale": "vi_VN"
      });

      document.getElementsByClassName('tradingview-widget-container__widget')[0].appendChild(script);

    }
  }, [product]);

  return (
    <div className="tradingview-widget-container">
      <div className="tradingview-widget-container__widget" style={{ height: 50 }}></div>
    </div>
  );
};

export default Ticket;
