export enum SystemRole {
  ADMIN = 'ADMIN',
  USER = 'USER',
  AGENT = 'AGENT'
}

export const systemRoleArray: SystemRole[] = [SystemRole.USER, SystemRole.AGENT, SystemRole.ADMIN,];

export const mapRoleToString: { [key in SystemRole]: string } = {
  [SystemRole.USER]: 'Người dùng',
  [SystemRole.ADMIN]: 'Quản trị viên',
  [SystemRole.AGENT]: 'Đại lý',
};


export enum SystemRole1 {
  USER = 'USER',
  AGENT = 'AGENT'
}

export const systemRoleArray1: SystemRole1[] = [SystemRole1.USER, SystemRole1.AGENT];

export const mapRoleToString1: { [key in SystemRole1]: string } = {
  [SystemRole1.USER]: 'Người dùng',
  [SystemRole1.AGENT]: 'Đại lý',
};

// Project Role

export enum RoleProjectEnum {
  MANAGER = 'MANAGER', // Quan ly
  EDITOR = 'EDITOR', // Bien tap vien
  STAFF = 'STAFF', // Nhan vien
}

export const roleProjectArray: RoleProjectEnum[] = [
  RoleProjectEnum.MANAGER,
  RoleProjectEnum.EDITOR,
  RoleProjectEnum.STAFF,
];

export const mapRoleProjectToString: { [key in RoleProjectEnum]: string } = {
  [RoleProjectEnum.MANAGER]: 'Quản lý',
  [RoleProjectEnum.EDITOR]: 'Biên tập viên',
  [RoleProjectEnum.STAFF]: 'Nhân viên',
};
