import { cisCameraPlus } from '@coreui/icons-pro'
import ArrowLeft from '@/components/shared/icons/web/ArrowLeft'
import { RootState } from '@/reducers'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { useDispatch, useSelector } from 'react-redux'
import Layout from './Layout'
import avatar from '../../../assets/img/avatar.gif'
import { handleUploadImage } from '@/shared/utils/ultils'
import { INewUser, IUser } from '@/shared/model/user.model'
import { checkErrorInputFile } from '@/components/shared/AvatarUpload/AvatarUploadContainer'
import { Formik } from 'formik'
import { CCardBody, CCardHeader, CCardTitle, CCol, CForm, CFormFeedback, CFormLabel, CImage, CLoadingButton, CRow } from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { AppDispatch } from '@/store'
import { fetching } from './webManagement.reducer'
import { updateEntity } from '../UserManagement/usersManagement.api'
import { ToastError, ToastSuccess } from '@/components/shared/toast/Toast'

const XacMinh = () => {
  const { navigate } = useRouter()
  const { user } = useSelector((state: RootState) => state.authentication)
  const dispatch = useDispatch<AppDispatch>();

  const { initialState } = useSelector(
    (state: RootState) => state.usersReducer,
  )

  const { updateEntitySuccess, loading, errorMessage } = initialState

  const initialValues: INewUser = {
    cccdFe: '',
    fileCccdFe: null,
    cccdBe: '',
    fileCccdBe: null,
  }


  const handleFileUploadCccdFe = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) => {
    const inputFiles = e.target.files
    if (inputFiles && inputFiles[0]) {
      if (!checkErrorInputFile([inputFiles[0]])) return
      const reader = new FileReader()
      reader.onload = (e) => {
        setFieldValue('fileCccdFe', inputFiles[0])
        setFieldValue('cccdFe', e.target?.result)
      }
      reader.readAsDataURL(inputFiles[0])
    }
  }


  const handleFileUploadCccdBe = async (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) => {
    const inputFiles = e.target.files
    if (inputFiles && inputFiles[0]) {
      if (!checkErrorInputFile([inputFiles[0]])) return
      const reader = new FileReader()
      reader.onload = (e) => {
        setFieldValue('fileCccdBe', inputFiles[0])
        setFieldValue('cccdBe', e.target?.result)
      }
      reader.readAsDataURL(inputFiles[0])
    }
  }


  const handleRemappingImageValue = async (value: INewUser) => {
    if (value.fileCccdFe) {
      const avatarUrlPromises = await handleUploadImage(value.fileCccdFe)
      value.cccdFe = avatarUrlPromises
    }
    if (value.fileCccdBe) {
      const avatarUrlPromises = await handleUploadImage(value.fileCccdBe)
      value.cccdBe = avatarUrlPromises
    }
    return value
  }


  return (
    <Layout>
      <div className="box-history-order">
        <div className="box-header d-flex justify-content-center align-items-center">
          <ArrowLeft
            style={{ position: 'absolute', left: 16 }}
            onClick={() => navigate(-1)}
          />

          <h5 className="text-white text-center mb-0 mt-0">Xác minh danh tính</h5>
        </div>

        <div>


        </div>



        <div className="px-3 mt-4">


          <Formik
            enableReinitialize
            initialValues={user || initialValues}
            // validationSchema={validationSchema}
            onSubmit={async (value, { resetForm }) => {
              if(!value.fileCccdFe || !value.fileCccdBe){
                ToastError('Vui chọn ảnh')
                return
              }
              const values = await handleRemappingImageValue(value)
              dispatch(fetching())
              dispatch(updateEntity(values as IUser))
              ToastSuccess('Gửi xác minh danh tính thành công')
              resetForm()
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleSubmit,
              resetForm,
              handleBlur,
            }) => (
              <CForm onSubmit={handleSubmit} className='form-xac-minh'>
                <CCardBody className="">
                  <CRow>

                    <CCol xs={12}>
                      <div className='p-3' style={{ background: "#212122" }}>
                        <CFormLabel className="fw-bold text-sm mb-0 required-field mb-3">
                          Tải lên mặt trước của chứng minh nhân dân
                        </CFormLabel>
                        <div className="d-flex justify-content-center position-relative">
                          <div className="profileImageContainer">
                            <div
                              className="position-relative"
                              style={{ height: 100 }}
                            >
                              {values.cccdFe ? (
                                <CImage
                                  color="secondary"
                                  rounded
                                  className="avatar-xxl object-fit-cover"
                                  src={values.cccdFe}
                                  style={{ width: '100%', height: '100%' }}
                                />
                              ) : (
                                <div
                                  className="d-flex flex-column justify-content-center align-items-center"
                                  style={{ width: '100%', height: '100%' }}
                                >
                                  <CIcon
                                    icon={cisCameraPlus}
                                    className="mb-3"
                                    style={{ width: '33px', height: '33px' }}
                                  />
                                  <p>Chọn ảnh</p>
                                </div>
                              )}
                            </div>
                            <CFormLabel
                              htmlFor="custom-task-file-input"
                              className={`p-image`}
                            >
                              {values.cccdFe ? (
                                <CIcon
                                  icon={cisCameraPlus}
                                  color="secondary"
                                  className="upload-button"
                                />
                              ) : (
                                <div className="add-image"></div>
                              )}
                              <input
                                id="custom-task-file-input"
                                className="file-upload"
                                type="file"
                                onClick={(e) => (e.currentTarget.value = '')}
                                // name="imageUrl"
                                onChange={(e) =>
                                  handleFileUploadCccdFe(e, setFieldValue)
                                }
                                // accept={inputFileTypesAccept}
                                accept="image/*"
                              />
                            </CFormLabel>
                          </div>
                        </div>
                        <CFormFeedback
                          invalid
                          className={
                            !!errors.cccdFe && touched.cccdFe
                              ? 'd-block'
                              : 'd-none'
                          }
                        >
                          {errors.cccdFe}
                        </CFormFeedback>
                      </div>
                    </CCol>

                    <CCol xs={12} className='mt-3'>
                      <div className='p-3' style={{ background: "#212122" }}>
                        <CFormLabel className="fw-bold text-sm mb-0 required-field text-white mb-3">
                          Tải lên mặt sau của chứng minh nhân dân
                        </CFormLabel>
                        <div className="d-flex justify-content-center position-relative">
                          <div className="profileImageContainer">
                            <div
                              className="position-relative"
                              style={{ height: 100 }}
                            >
                              {values.cccdBe ? (
                                <CImage
                                  color="secondary"
                                  rounded
                                  className="avatar-xxl object-fit-cover"
                                  src={values.cccdBe}
                                  style={{ width: '100%', height: '100%' }}
                                />
                              ) : (
                                <div
                                  className="d-flex flex-column justify-content-center align-items-center"
                                  style={{ width: '100%', height: '100%' }}
                                >
                                  <CIcon
                                    icon={cisCameraPlus}
                                    className="mb-3"
                                    style={{ width: '33px', height: '33px' }}
                                  />
                                  <p>Chọn ảnh</p>
                                </div>
                              )}
                            </div>
                            <CFormLabel
                              htmlFor="custom-task-file-input-1"
                              className={`p-image`}
                            >
                              {values.cccdBe ? (
                                <CIcon
                                  icon={cisCameraPlus}
                                  color="secondary"
                                  className="upload-button"
                                />
                              ) : (
                                <div className="add-image"></div>
                              )}
                              <input
                                id="custom-task-file-input-1"
                                className="file-upload"
                                type="file"
                                onClick={(e) => (e.currentTarget.value = '')}
                                onChange={(e) =>
                                  handleFileUploadCccdBe(e, setFieldValue)
                                }
                                accept="image/*"
                              />
                            </CFormLabel>
                          </div>
                        </div>
                        <CFormFeedback
                          invalid
                          className={
                            !!errors.cccdBe && touched.cccdBe
                              ? 'd-block'
                              : 'd-none'
                          }
                        >
                          {errors.cccdBe}
                        </CFormFeedback>
                      </div>
                    </CCol>


                  </CRow>
                  <CRow className="mt-4">
                    <CCol className="d-flex justify-content-end" >
                      <CLoadingButton
                        loading={loading}
                        className="text-white custom-form-btn ms-2 green-btn w-100"
                        type="submit"
                        style={{ borderRadius: 25, background: "#00be06" }}
                        disabled={user?.isVerify ? true : false}
                      >
                        Gửi
                      </CLoadingButton>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CForm>
            )}
          </Formik>


        </div>
      </div>
    </Layout>
  )
}
export default XacMinh
