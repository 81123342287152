import io from 'socket.io-client';
import { SOCKET_URL } from './constants';

const token = localStorage.getItem('authentication_token') || sessionStorage.getItem('authentication_token');

export const socket = io(SOCKET_URL, {
  //   Thêm các options cần thiết
  extraHeaders: {
    "token": token || ""
  }
});
