import { RootState } from '@/reducers'
import MenuBottom from './MenuBottom'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { useEffect, useState } from 'react'
import { socket } from '@/shared/config/socket'
import { AppDispatch } from '@/store'
import { getProfile } from '../auth/auth.api'
import SModal from '@/components/shared/Modal/SModal'
import {
  CButton,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from '@coreui/react-pro'
import { ITransaction } from '@/shared/model/transaction.model'

import qua1 from '../../../assets/img/qua1.png'
import { IHistory } from '@/shared/model/history.model'
import { formatVND } from '@/shared/utils/ultils'


const token =
  localStorage.getItem('authentication_token') ||
  sessionStorage.getItem('authentication_token')

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { navigate, location } = useRouter()
  const dispatch = useDispatch<AppDispatch>()
  const { user } = useSelector((state: RootState) => state.authentication)
  const [visible, setVisible] = useState(false)
  const [visibleTraThuong, setVisibleTraThuong] = useState(false)
  const [dataTraThuong, setDataTraThuong] = useState<IHistory | null>(null)

  const [deposit, setDeposit] = useState<ITransaction>()


  useEffect(() => {
    const element = document.getElementById('cw-bubble-holder');
    if(element && location.pathname !== '/login' && location.pathname !== '/register') {
      element.style.display = 'none';
    }else {
      if(element){
        element.style.display = 'block';
      }
    }
  }, [location.pathname]);


  useEffect(() => {
    console.log('data1231323', user?.id)

    socket.on('connect', () => {
      console.log('Connected to server')
    })

    socket.on(`user_${user?.id}_new_message`, (newMsg: any) => {
      console.log('Bạn có tin nhắn mới: ', newMsg)
    })
    console.log('data', user?.id)

    socket.on(`user_${user?.id}_event_nhan_qua`, (data: any) => {
      setVisibleTraThuong(true)
      setDataTraThuong(data)
      dispatch(getProfile())
    })

    socket.on(`user_${user?.id}_new_deposit`, (data: any) => {
      console.log('data', data)

      if (data) {
        dispatch(getProfile())
      }

      if (data && data.isNotify) {
        setDeposit(data)
        setVisible(true)
      }
    })

    return () => {
      // console.log('disconnect');
      // socket.off('connect')
      socket.off(`user_${user?.id}_new_message`)
      socket.off(`user_${user?.id}_new_deposit`)
      // socket.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnClose = () => {
    setVisible(false)
  }



  return (
    <>
      <div className="container-web">
        {children}
        <MenuBottom />
      </div>
      <div>
        <SModal
          visible={visible}
          onClose={handleOnClose}
          backdrop="static"
          className="custom-modal"
          alignment="center"
        >
          {/* <CModalHeader>
            <h5>Thông báo Nạp Tiền</h5>
          </CModalHeader> */}
          <CModalBody>
            <div className='text-center'>
              <img width={120} src={qua1} />
            </div>

            <h1 className="text-center my-2">Thông báo</h1>
            <p style={{color: "gray"}} className='mt-3'>{deposit?.message}</p>
     
          </CModalBody>
          <CModalFooter className="justify-content-center">
            <CButton onClick={handleOnClose} style={{background: "#FD0404"}}>Đồng ý</CButton>
          </CModalFooter>
        </SModal>
      </div>


      {/* Trả thưởng */}
      <div>
        <SModal
          visible={visibleTraThuong}
          onClose={handleOnClose}
          backdrop="static"
          className="custom-modal"
          alignment="center"
        >
          <CModalBody>
            <h5 className="text-center my-2">Lệnh: #{dataTraThuong?.KyId}</h5>

            <div className='text-center mt-3'>
              <p className='fs-5 my-3'>{ dataTraThuong?.isWin === true ? 'Chúc mừng bạn' : 'May mắn lần sau' }</p>
              <p className={ dataTraThuong?.isWin === true ? 'fs-5 mb-0 text-success' : 'fs-5 mb-0 text-danger' }>{ dataTraThuong?.isWin === true ? '+' + formatVND(dataTraThuong?.addMoney ?? 0) : '-' + formatVND(dataTraThuong?.addMoney ?? 0) }</p>
            </div>

          </CModalBody>
          <CModalFooter className="justify-content-center">
            <CButton onClick={() => setVisibleTraThuong(false)} style={{background: "#FD0404"}}>Xác nhận</CButton>
          </CModalFooter>
        </SModal>
      </div>

    </>
  )
}

export default Layout
