import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import SettingsManagement from './component/SettingsManagement';


const SettingsManagementLayout: RouteObject[] = [
  {
    path: '/',
    element: (
        <SettingsManagement />
    ),
  },
  { path: '*', element: <Navigate to="/404" /> },
];

const SettingsManagementRoutes = () => useRoutes(SettingsManagementLayout);
export default SettingsManagementRoutes;
