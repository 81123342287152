import { createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RootState } from '../../../reducers';
import { IBank } from '../../../shared/model/bank.model';
import { IInitialState, IResponse } from '../../../shared/shared-interfaces';

import {
  createEntity,
  getEntities,
  getEntity,
  IBankParams,
  removeEntity,
  updateDeleteEntity,
  updateEntity,
} from './banksManagement.api';

interface IBanksInitialState extends IInitialState {
  updateEntityStatusSuccess: boolean;
  filterState: IBankParams;
}

export const initialBanksFilter: IBankParams = {
  page: 1,
  limit: 10,
  sortBy: 'id',
  sortOrder: 'DESC',
};

const initialState: IBanksInitialState = {
  fetchEntitiesSuccess: false,
  fetchEntitySuccess: false,
  updateEntitySuccess: false,
  deleteEntitySuccess: false,
  loading: false,
  errorMessage: null,
  totalItems: 0,
  totalPages: 0,
  updateEntityStatusSuccess: false,
  filterState: initialBanksFilter,
};

export const banksAdapter = createEntityAdapter<IBank>({
  selectId: ({ bankId }) => bankId,
});

const { actions, reducer } = createSlice({
  name: 'banksSlice',
  initialState: banksAdapter.getInitialState({ initialState }),
  reducers: {
    setFilterState(state, { payload }: PayloadAction<IBankParams>) {
      state.initialState.filterState = payload;
    },
    fetching(state) {
      state.initialState.loading = true;
    },
    resetAll(state) {
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
      state.initialState.fetchEntitySuccess = false;
      state.initialState.updateEntitySuccess = false;
      state.initialState.deleteEntitySuccess = false;
      state.initialState.errorMessage = null;
    },
    resetEntity(state) {
      state.initialState.updateEntitySuccess = false;
      state.initialState.errorMessage = null;
      state.initialState.deleteEntitySuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getEntities.fulfilled.type,
      (state, { payload }: PayloadAction<AxiosResponse<IResponse<IBank[]>>>) => {
        banksAdapter.setAll(state, payload.data.data);
        state.initialState.totalItems = Number(payload.data.count);
        state.initialState.totalPages = Number(payload.data.total_pages);
        state.initialState.fetchEntitiesSuccess = true;
        state.initialState.loading = false;
      }
    );
    builder.addCase(getEntities.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitiesSuccess = false;
    });

    builder.addCase(getEntity.fulfilled.type, (state, { payload }: PayloadAction<IBank>) => {
      banksAdapter.upsertOne(state, payload);
      state.initialState.fetchEntitySuccess = true;
      state.initialState.loading = false;
    });
    builder.addCase(getEntity.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.fetchEntitySuccess = false;
    });
    builder.addCase(updateEntity.fulfilled.type, (state, { payload }: PayloadAction<IBank>) => {
      banksAdapter.updateOne(state, { id: payload.bankId, changes: payload });
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    });
    builder.addCase(updateEntity.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    });
    builder.addCase(createEntity.fulfilled.type, (state, { payload }: PayloadAction<IBank>) => {
      banksAdapter.addOne(state, payload);
      state.initialState.updateEntitySuccess = true;
      state.initialState.loading = false;
    });
    builder.addCase(createEntity.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.updateEntitySuccess = false;
    });
    builder.addCase(removeEntity.fulfilled.type, (state, { payload }: PayloadAction<string>) => {
      banksAdapter.removeOne(state, payload);
      state.initialState.totalItems -= 1;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    });
    builder.addCase(removeEntity.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    });
    builder.addCase(updateDeleteEntity.fulfilled.type, (state, { payload }: PayloadAction<string[]>) => {
      banksAdapter.removeMany(state, payload);
      state.initialState.totalItems -= payload.length;
      state.initialState.deleteEntitySuccess = true;
      state.initialState.loading = false;
    });
    builder.addCase(updateDeleteEntity.rejected.type, (state, { payload }: PayloadAction<any>) => {
      state.initialState.errorMessage = payload?.message;
      state.initialState.loading = false;
      state.initialState.deleteEntitySuccess = false;
    });
  },
});

export const { fetching, resetAll, resetEntity, setFilterState } = actions;
export default reducer;

export const bankSelectors = banksAdapter.getSelectors<RootState>((state) => state.banksReducer);

const { selectById } = banksAdapter.getSelectors();
const getBankState = (rootState: RootState) => rootState.banksReducer;

export const selectEntityById = (id: string) => {
  return createSelector(getBankState, (state) => selectById(state, id));
};
