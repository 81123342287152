import { SVGProps } from "react";

const ArrowLeft = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props?.width || 20}
            height={props?.height || 20}
            fill="none"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M15.707 4.293a1 1 0 010 1.414L9.414 12l6.293 6.293a1 1 0 01-1.414 1.414l-7-7a1 1 0 010-1.414l7-7a1 1 0 011.414 0z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default ArrowLeft;
