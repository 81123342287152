import Layout from './Layout'
import avatar from '../../../assets/img/avatar.gif'
import { NavLink } from 'react-router-dom'
import WithdrawalIcon from '@/components/shared/icons/web/WithdrawalIcon'
import ArrowNarrowLeftIcon from '@/components/shared/icons/ArrowNarrowLeftIcon'
import ArrowRight from '@/components/shared/icons/web/ArrowRight'
import CardIcon from '@/components/shared/icons/web/CardIcon'
import MenuIcon1 from '@/components/shared/icons/web/MenuIcon1'
import MenuIcon2 from '@/components/shared/icons/web/MenuIcon2'
import MenuIcon3 from '@/components/shared/icons/web/MenuIcon3'
import ChangePassWordIcon from '@/components/shared/icons/web/ChangePassWordIcon'
import LogoutIcon from '@/components/shared/icons/header/LogoutIcon'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/reducers'
import { AppDispatch } from '@/store'
import { logout, resetAll } from '../auth/auth.reducer'
import MenuIcon2_1 from '@/components/shared/icons/web/MenuIcon2_1'
import SModal from '@/components/shared/Modal/SModal'
import {
  CButton,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from '@coreui/react-pro'
import { useState } from 'react'
import MenuIcon1_1 from '@/components/shared/icons/web/MenuIcon1_1'
import { formatVND } from '@/shared/utils/ultils'
import card from '../../../assets/img/card.svg'
import {
  AiOutlineInfoCircle, AiOutlineBank, AiOutlinePlusSquare,
  AiOutlineMinusSquare, AiOutlineIdcard, AiOutlineLock, AiOutlineBell,
  AiOutlineCustomerService, AiOutlineDownload
} from "react-icons/ai";

const Account = () => {
  const { user } = useSelector((state: RootState) => state.authentication)
  const dispatch = useDispatch<AppDispatch>()

  const [visible, setVisible] = useState(false)

  const onLogout = () => {
    dispatch(logout())
    dispatch(resetAll())
  }

  return (
    <Layout>
      <div className="box-account px-3">
        <div className="box-header d-flex align-items-center gap-2 py-3">
          <img
            width={50}
            height={50}
            style={{ borderRadius: '50%' }}
            src={avatar}
          />
          <div className="content">
            <div>{user?.username}</div>
            <span>ID: {user?.id}</span>
          </div>
        </div>

        <div className="info d-flex justify-content-between">
          <div>
            <div>Giá trị tài sản (VND)</div>
            <div>{formatVND(user?.money ?? 0)}</div>
          </div>
          <div className="d-flex align-items-center gap-2">
            <NavLink to="/withdraw1" className="muavnd text-decoration-none">
              Nạp tiền
            </NavLink>
            <NavLink to="/withdraw" className="banvnd text-decoration-none">
              Rút tiền
            </NavLink>
          </div>
        </div>

        <div className="box-1">
          <div className="heading">Cá nhân</div>

          <div className="bcontent">
            <NavLink to={'/account-infor'} className="d-flex item">
              <AiOutlineInfoCircle size={19} />
              <span >Hồ sơ của tôi</span>
            </NavLink>

            <NavLink to={'/add-bank'} className="d-flex item">
              <AiOutlineBank size={19} />
              <span>Ngân hàng</span>
            </NavLink>

            <NavLink to={'/history-withdraw'} className="d-flex item">
              <AiOutlineMinusSquare size={19} />
              <span>Lịch sử rút</span>
            </NavLink>

            <NavLink to={'/history-deposit'} className="d-flex item">
              <AiOutlinePlusSquare size={19} />
              <span>Lịch sử nạp tiền</span>
            </NavLink>

            <NavLink to={'/xac-minh-danh-tinh'} className="d-flex item">
              <AiOutlineIdcard size={19} />
              <span>Xác minh danh tính</span>
            </NavLink>

            <NavLink to={'/change-password'} className="d-flex item">
              <AiOutlineLock size={19} />
              <span>Đổi mật khẩu</span>
            </NavLink>
            <NavLink to={'/thongbao'} className="d-flex item">
              <AiOutlineBell size={19} />
              <span>Thông báo của tôi</span>
            </NavLink>


            <NavLink to={'/change-password-withdraw'} className="d-flex item">
              <AiOutlineLock size={19} />
              <span>Cài đặt mật khẩu rút tiền</span>
            </NavLink>
          </div>
        </div>

        <div className="box-1">
          <div className="heading">Khác</div>

          <div className="bcontent">
            <NavLink to={'/message'} className="d-flex item">
              <AiOutlineCustomerService size={19} />
              <span>Chăm sóc khách hàng</span>
            </NavLink>

            <NavLink to={'/ve-chung-toi'} className="d-flex item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                version="1.1"
                className="svg1"
              >
                <title>Home</title>{' '}
                <g
                  id="Page-1"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g id="Home">
                    <rect
                      id="Rectangle"
                      fill-rule="nonzero"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    ></rect>{' '}
                    <path
                      d="M5,10 L5,19 C5,19.5523 5.44772,20 6,20 L18,20 C18.5523,20 19,19.5523 19,19 L19,10"
                      id="Path"
                      stroke="#fff"
                      stroke-width="2"
                      stroke-linecap="round"
                    ></path>{' '}
                    <path
                      d="M21,11 L12.307,4.23875 C12.1264,4.09832 11.8736,4.09832 11.693,4.23875 L3,11"
                      id="Path"
                      stroke="#fff"
                      stroke-width="2"
                      stroke-linecap="round"
                    ></path>
                  </g>
                </g>
              </svg>
              <span>Về chúng tôi</span>
            </NavLink>

            <NavLink to={'/dieu-khoan-su-dung'} className="d-flex item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#fff"
                width="18"
                height="18"
                viewBox="0 0 56 56"
                className="svg1"
              >
                <path d="M 15.5547 53.125 L 40.4453 53.125 C 45.2969 53.125 47.7109 50.6640 47.7109 45.7890 L 47.7109 24.5078 C 47.7109 21.4844 47.3828 20.1718 45.5078 18.2500 L 32.5703 5.1015 C 30.7891 3.2734 29.3359 2.8750 26.6875 2.8750 L 15.5547 2.8750 C 10.7266 2.8750 8.2891 5.3594 8.2891 10.2344 L 8.2891 45.7890 C 8.2891 50.6875 10.7266 53.125 15.5547 53.125 Z M 15.7422 49.3515 C 13.3281 49.3515 12.0625 48.0625 12.0625 45.7187 L 12.0625 10.3047 C 12.0625 7.9844 13.3281 6.6484 15.7656 6.6484 L 26.1718 6.6484 L 26.1718 20.2656 C 26.1718 23.2187 27.6718 24.6718 30.5781 24.6718 L 43.9375 24.6718 L 43.9375 45.7187 C 43.9375 48.0625 42.6953 49.3515 40.2578 49.3515 Z M 31.0000 21.1328 C 30.0859 21.1328 29.7109 20.7578 29.7109 19.8203 L 29.7109 7.3750 L 43.2109 21.1328 Z M 36.6250 31.1172 L 18.8359 31.1172 C 17.9922 31.1172 17.3828 31.7500 17.3828 32.5469 C 17.3828 33.3672 17.9922 34.0000 18.8359 34.0000 L 36.6250 34.0000 C 37.4453 34.0000 38.0781 33.3672 38.0781 32.5469 C 38.0781 31.7500 37.4453 31.1172 36.6250 31.1172 Z M 36.6250 39.2969 L 18.8359 39.2969 C 17.9922 39.2969 17.3828 39.9531 17.3828 40.7734 C 17.3828 41.5703 17.9922 42.1797 18.8359 42.1797 L 36.6250 42.1797 C 37.4453 42.1797 38.0781 41.5703 38.0781 40.7734 C 38.0781 39.9531 37.4453 39.2969 36.6250 39.2969 Z"></path>
              </svg>
              <span>Điều khoản sử dụng</span>
            </NavLink>

            <NavLink to={'/chinh-sach-bao-mat'} className="d-flex item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#fff"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                className="svg1"
              >
                <path d="M12,22A17.5,17.5,0,0,0,21,6.7V6L12,2,3,6v.7A17.5,17.5,0,0,0,12,22ZM11,6h2V8H11Zm0,4h2v8H11Z"></path>
              </svg>
              <span>Chính sách bảo mật</span>
            </NavLink>

            <NavLink to={'/currencystar.mobileconfig'} className="d-flex item">
              <AiOutlineDownload size={19} />
              <a className='text-decoration-none text-white'>Tải xuống ứng dụng</a>
            </NavLink>
          </div>
        </div>

        <div onClick={onLogout} className="blogout">
          Thoát
        </div>

        {/* <div className="box-header">
          <div className="d-flex gap-2 align-items-center my-2 justify-content-between">
            <div className="text-white" style={{ fontSize: 15 }}>
              ID: {user?.id}
            </div>
            <div className="text-white">{user?.username}</div>
          </div>

          <div className="pb-5 d-flex justify-content-center text-center flex-column">
            <div style={{ fontSize: 14 }} className="text-white mt-2">
              Số dư
            </div>
            <div className="text-white fs-4 mt-3">
              {new Intl.NumberFormat('vi-VN').format(user?.money || 0)}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <div
            className="d-flex"
            style={{
              padding: 10,
              borderRadius: 10,
              borderColor: '#e2e5ec',
              borderWidth: 1,
              borderStyle: 'solid',
              background: '#113b49',
              marginTop: '-36px',
            }}
          >
            <div
              className="text-white d-flex justify-content-center align-items-center flex-column"
              style={{
                fontSize: 14,
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: 35,
                paddingLeft: 35,
              }}
            >
              <svg
                style={{ fill: '#fff', width: 22, height: 22 }}
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 22 22"
                data-testid="AddCardOutlinedIcon"
              >
                <path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h10v-2H4v-6h18V6c0-1.11-.89-2-2-2zm0 4H4V6h16v2zm4 9v2h-3v3h-2v-3h-3v-2h3v-3h2v3h3z"></path>
              </svg>
              <span className="mt-1" onClick={() => setVisible(true)}>
                Nạp điểm
              </span>
            </div>
            <div
              style={{
                borderLeftWidth: 1,
                borderLeftStyle: 'solid',
                borderLeftColor: '#fff',
              }}
            ></div>
            <NavLink
              to="/withdraw"
              className="text-decoration-none text-white d-flex justify-content-center align-items-center flex-column"
              style={{
                fontSize: 14,
                paddingTop: 0,
                paddingBottom: 0,
                paddingRight: 35,
                paddingLeft: 35,
              }}
            >
              <svg
                style={{ fill: '#fff', width: 22, height: 22 }}
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                focusable="false"
                aria-hidden="true"
                viewBox="0 0 24 24"
                data-testid="PriceChangeOutlinedIcon"
              >
                <path d="M8 17h2v-1h1c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1H8v-1h4V8h-2V7H8v1H7c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h3v1H6v2h2v1zM20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4V6h16v12zm-6-8 2-2 2 2m0 4.25-2 2-2-2"></path>
              </svg>
              <span className="mt-1">Rút điểm</span>
            </NavLink>
          </div>
        </div>

        <div className="list-menu mt-4">
          <NavLink to={'/history-order'} className="mt-4">
            <div
              className="d-flex align-items-center gap-3"
              style={{ color: '#113b49', fontSize: 17 }}
            >
              <MenuIcon1 color="#113b49" width={24} height={24} />
              Lịch sử tham gia
            </div>
            <ArrowRight color={'#000'} width={12} height={12} />
          </NavLink>

          <NavLink to={'/history-order'} className="mt-4">
            <div
              className="d-flex align-items-center gap-3"
              style={{ color: '#113b49', fontSize: 17 }}
            >
              <MenuIcon1_1 color="#113b49" width={24} height={24} />
              Biến động số dư
            </div>
            <ArrowRight color={'#000'} width={12} height={12} />
          </NavLink>

          <NavLink to={'/history-deposit'} className="mt-4">
            <div
              className="d-flex align-items-center gap-3"
              style={{ color: '#113b49', fontSize: 17 }}
            >
              <MenuIcon2 color="#000" width={24} height={24} />
              Lịch sử nạp
            </div>
            <ArrowRight color={'#000'} width={12} height={12} />
          </NavLink>

          <NavLink to={'/history-withdraw'} className="mt-4">
            <div
              className="d-flex align-items-center gap-3"
              style={{ color: '#113b49', fontSize: 17 }}
            >
              <MenuIcon2_1 color="#000" width={24} height={24} />
              Lịch sử rút
            </div>
            <ArrowRight color={'#000'} width={12} height={12} />
          </NavLink>

          <NavLink to={'/add-bank'} className="mt-4">
            <div
              className="d-flex align-items-center gap-3"
              style={{ color: '#113b49', fontSize: 17 }}
            >
              <MenuIcon3 color="#000" width={24} height={24} />
              Ngân hàng
            </div>
            <ArrowRight color={'#000'} width={12} height={12} />
          </NavLink>

          <NavLink to={'/change-password'} className="mt-4">
            <div
              className="d-flex align-items-center gap-3"
              style={{ color: '#113b49', fontSize: 17 }}
            >
              <ChangePassWordIcon color="#000" width={24} height={24} />
              Đổi mật khẩu
            </div>
            <ArrowRight color={'#000'} width={12} height={12} />
          </NavLink>

          <NavLink to={'#'} className="mt-4" onClick={onLogout}>
            <div
              className="d-flex align-items-center gap-3"
              style={{ color: '#113b49', fontSize: 17 }}
            >
              <LogoutIcon width={24} height={24} />
              Đăng xuất
            </div>
            <ArrowRight color={'#000'} width={12} height={12} />
          </NavLink>
        </div> */}
      </div>

      <div>
        <SModal
          visible={visible}
          backdrop="static"
          className="custom-modal"
          alignment="center"
        >
          <CModalBody>
            <h2 className="text-center mb-4">NẠP ĐIỂM</h2>
            <p className="m-0">
              Vui lòng liên hệ CSKH để được hướng dẫn nạp điểm
            </p>
          </CModalBody>
          <CModalFooter className="justify-content-center">
            <CButton
              style={{ background: '#ddd', color: '#000', boxShadow: 'none' }}
              onClick={() => setVisible(false)}
            >
              Đóng
            </CButton>
          </CModalFooter>
        </SModal>
      </div>
    </Layout>
  )
}

export default Account
