import Layout from './Layout'
import image3 from '../../../assets/img/laiquadem.png'
import { NavLink } from 'react-router-dom'
import { useEffect, useState } from 'react'
import axios from '../../../shared/config/axios-interceptor'
import { formatVND } from '@/shared/utils/ultils'
import dayjs from "dayjs"
import { useSelector } from 'react-redux'
import { RootState } from '@/reducers'

const DauTu = () => {
  const [dautu, setDauTu] = useState<any[]>([])
  const { user } = useSelector((state: RootState) => state.authentication)
  const [laixuat, setLaixuat] = useState<any>([])

  const getDauTu = async () => {
    try {
      const response = await axios.get('/dautu')
      setDauTu(response.data.data)
      console.log('Success:', response.data)
    } catch (error) {
      console.error('Error:', error)
    }
  }


  const getPhien = async () => {
    try {
      const response = await axios.get('/settings/1')
      
      if (response?.data) {
        const { content5, content6 } = response.data

        if (content5 && content6) {

          const listDataPhien: any = [];

          content5.split(',').forEach(async (phien: any) => {
            const phienNumber: Number = Number(phien.trim());
            if (typeof phienNumber === 'number') {
              listDataPhien.push({ name: phienNumber, description: 0 });
            }
          });



          content6.split(',').forEach(async (phien: any, index: number) => {
            const phienNumber: Number = Number(phien.trim());
            if (typeof phienNumber === 'number') {
              listDataPhien[index].description = phienNumber;
            }
          });

          setLaixuat(listDataPhien);
        }

      }

    } catch (error) {
      console.error('Error:', error)
    }
  }

  
  useEffect(() => {
    getDauTu()
    // getLaiXuat()
    getPhien();
  }, [])

  const checkPhanTram = () => {
    if (!laixuat.length) return 0;

    if((user?.money ?? 0) > Number(laixuat[0].name) && (user?.money ?? 0) < Number(laixuat[1].name)){
      return laixuat[0].description
    }else if((user?.money ?? 0) > Number(laixuat[1].name) && (user?.money ?? 0) < Number(laixuat[2].name)){
      return laixuat[1].description
    }else if((user?.money ?? 0) > Number(laixuat[2].name) && (user?.money ?? 0) < Number(laixuat[3].name)){
      return laixuat[2].description
    }else if((user?.money ?? 0) > Number(laixuat[3].name) && (user?.money ?? 0) < Number(laixuat[4].name)){
      return laixuat[3].description
    }else if((user?.money ?? 0) > Number(laixuat[4].name)){
      return laixuat[4].description
    }
  }

  const checkLai = () => {
    if (!laixuat.length) return 0;
    const laiPhanTram = checkPhanTram();
    return (user?.money ?? 0) * Number(laiPhanTram) / 100;;
  }

  return (
    <Layout>
      <div className="dautu">
        <div className="page-bd">
          <div className="wrapper">
            <div className="top">
              <h1>Lãi qua đêm</h1> <h2>Tiết kiệm linh hoạt</h2>
              <div className="image">
                <img className="a1" src={image3} alt="lai qua dem" />
                <img src={image3} alt="lai qua dem" />
              </div>
            </div>
            <div className="bottom">
              <div className="head1 cls">
                <span className="sp1">Đang nhận lãi</span>
                <span className="sp2">
                  <NavLink to={'/chinh-sach-lai-xuat'}>Chính sách</NavLink>
                </span>
              </div>
              <div className="box">
                <div className="row1">
                  <p className="m-0">Lãi hôm nay (dự kiến)</p>
                  <p className="p2">+{ formatVND(checkLai()) }</p>
                </div>
                <div className="row2 cls">
                  <div className="d1">
                    <p className="p22">Linh hoạt</p> <p>Tài sản</p>
                    <p className="p22">{formatVND(user?.money ?? 0)}</p>
                  </div>
                  <div className="d2">
                    <p>&nbsp;</p> <p>MPY(%/ngày)</p> <p className="p22">{checkPhanTram()} %</p>
                  </div>
                  <div className="d3">
                    <p>&nbsp;</p> <p>Phần thưởng</p> <p className="p3">+0</p>
                  </div>
                </div>
              </div>
              <div className="head11 cls">
                <span className="sp11">Lịch sử</span>
              </div>
              <div className="history mt-3">
                {/* <div className="title">Tiền lãi đã nhận</div>
                <p>0 VND</p> */}
                
                {dautu.length === 0 && <h3
                  className="text-center"
                  style={{ color: '#99999b', fontSize: 16 }}
                >
                  Chưa có lãi xuất qua đêm
                </h3> }

                <div className="list-history">
                  {dautu?.map((item: any, index: number) => (
                    <div className="item-history" key={index}>
                      <span className="color-green">+ {formatVND(item.addMoney)}</span>
                      <span>{dayjs(item.createdDate).format('DD-MM-YYYY')}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default DauTu
