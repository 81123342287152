import { createAsyncThunk } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';
import axios from '../../../shared/config/axios-interceptor';
import { ResponseStatus } from '../../../shared/enumeration/ResponseStatus';
import { INewBank, IBank } from '../../../shared/model/bank.model';
import { IParams } from '../../../shared/shared-interfaces';

const prefix = 'bank';
// const history = 'revision';

export interface IBankParams extends IParams {
  status?: ResponseStatus;
  projectId?: number; // Lấy tất cả bank không phải là nhân viên của dự án được truyền vào
  keyword?: string; // Lấy tất cả bank không phải là nhân viên của dự án được truyền vào
}

export const getEntities = createAsyncThunk(`get-all-${prefix}`, async (fields: IBankParams, thunkAPI) => {
  try {
    const params = pickBy(fields);
    return await axios.get<IBank[]>(`${prefix}`, { params });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEntity = createAsyncThunk(`get-single-${prefix}`, async (id: string, thunkAPI) => {
  try {
    const { data } = await axios.get<IBank>(`${prefix}/${id}`);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createEntity = createAsyncThunk(`create-one-${prefix}`, async (body: INewBank, thunkAPI) => {
  try {
    const { data } = await axios.post(`${prefix}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateEntity = createAsyncThunk(`update-one-${prefix}`, async (body: IBank, thunkAPI) => {
  try {
    const { bankId } = body;
    const { data } = await axios.put<IBank>(`${prefix}/${bankId}`, pickBy(body));
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const removeEntity = createAsyncThunk(`delete-one-${prefix}`, async (bankId: string, thunkAPI) => {
  try {
    await axios.delete(`${prefix}/${bankId}`);
    return bankId;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export interface IBankDel {
  id: number[];
  status: ResponseStatus;
}

export const updateDeleteEntity = createAsyncThunk(
  `delete-status-${prefix}`,
  async (requestBody: IBankDel, thunkAPI) => {
    try {
      await axios.post<IBank>(`${prefix}/status`, requestBody);
      return requestBody.id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
